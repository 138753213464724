import React from "react";
// import { CloseOutlined } from "@mui/icons-material";
import { InfoOutlined } from "@ant-design/icons";
// import { Checkbox } from "antd";
import { InfoParlaySoccerTmp } from "../../../../../../api/betting/bettingApi";
import { soccer_2, Trash } from "../../../../../../components/imgExport";
interface Props {
    index: number
    item: InfoParlaySoccerTmp
    listParlayBetsSlip: InfoParlaySoccerTmp[]

    rStrightBetsSlipByIndex: (value: number) => void
    setListParlayBetsSlip?: (value: InfoParlaySoccerTmp[]) => void

}
const ItemParlaySoccer: React.FC<Props> = ({
    index, item, listParlayBetsSlip,
    rStrightBetsSlipByIndex, setListParlayBetsSlip
}) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCheck = (e: boolean) => {
        var arr = listParlayBetsSlip.map((item, indexc) => (indexc === index ? { ...item, isCheck: e } : item));
        setListParlayBetsSlip?.(arr)
    }

    return (
        // <div className="border border-[#202531] rounded-[2px] p-3 flex flex-col">
        //     <div className="flex justify-between">
        //         <div className="flex justify-start">
        //             <div className={`${item.isWarning ? "" : "hidden"} flex items-center`}>
        //                 <InfoOutlined className="bg-[#ffc700] p-[1px] rounded-sm" />
        //             </div>
        //             <div className="flex flex-col">
        //                 <div className='flex justify-start pl-2 gap-2'>
        //                     <img src={soccer_2} alt="" />
        //                     <span className='text-base text-white'>
        //                         Soccer
        //                     </span>
        //                     <span className='text-base text-white'>{item?.lineBetting}</span>
        //                 </div>
        //                 <div className='flex justify-start pl-2 text-left'>
        //                     <span className='text-base text-white'>{item.game}</span>
        //                 </div>

        //             </div>
        //         </div>
        //         <div className='flex'>
        //             <img src={Trash} alt=""
        //                 onClick={() => rStrightBetsSlipByIndex?.(index)}
        //             />
        //         </div>
        //     </div>

        //     <div className='flex justify-between' key={`Parlay-${index}`}>
        //         <div className='flex justify-between'>
        //             {/* <Checkbox
        //                 checked={item.isCheck}
        //                 onChange={(e) => handleCheck(!item.isCheck)}
        //             ></Checkbox> */}
        //             <span className='text-base text-white pl-2'>
        //                 {`  ${item?.teamChoose} ${item.scoreChoose.split(" ")[0]} `}
        //             </span>
        //             <span className='pl-1 text-base text-white whitespace-nowrap'>
        //                 {item.scoreChoose.split(' ')[1] ? `@ ${Number(item.scoreChoose.split(' ')[1]).toFixed(2)}` : ""}
        //             </span>
        //         </div>
        //     </div>


        // </div>
        // <div className="border border-[#202531] rounded-[2px] p-3 flex flex-col">
        //     <div className="flex w-full">
        //         <div className="flex w-full">
        //             <div className="flex gap-2 w-full">
        //                 <div className="flex flex-col pl-2 w-full">
        //                     <div className='flex justify-between gap-2'>
        //                         <div className="flex gap-2">
        //                             <div className={`${item.isWarning ? "" : "hidden"} flex items-center`}>
        //                                 <InfoOutlined className="bg-[#ffc700] p-[1px] rounded-sm" />
        //                             </div>
        //                             <img src={soccer_2} alt="" />
        //                             <span className='text-base text-white'>
        //                                 Soccer
        //                             </span>
        //                         </div>

        //                         <img src={Trash} alt=""
        //                             onClick={() => rStrightBetsSlipByIndex?.(index)}
        //                         />
        //                     </div>
        //                     <div className='flex justify-start gap-2'>
        //                         <span className='text-base text-white'>{item?.lineBetting}</span>
        //                     </div>
        //                     <div className='flex justify-start text-left'>
        //                         <span className='text-base text-white'>{item.game}</span>
        //                     </div>

        //                 </div>
        //             </div>

        //         </div>
        //         <div className='flex'>

        //         </div>
        //     </div>

        //     <div className='flex justify-between' key={`Parlay-${index}`}>
        //         <div className='flex justify-between'>
        //             {/* <Checkbox
        //                 checked={item.isCheck}
        //                 onChange={(e) => handleCheck(!item.isCheck)}
        //             ></Checkbox> */}
        //             <span className='text-base text-white pl-2'>
        //                 {`  ${item?.teamChoose} ${item.scoreChoose.split(" ")[0]} `}
        //             </span>
        //             <span className='pl-1 text-base text-white whitespace-nowrap'>
        //                 {item.scoreChoose.split(' ')[1] ? `@ ${Number(item.scoreChoose.split(' ')[1]).toFixed(2)}` : ""}
        //             </span>
        //         </div>
        //     </div>


        // </div>
        <div className="border border-[#202531] rounded-[2px] p-3 flex flex-col">
            <div className="flex w-full">
                <div className="flex w-full">
                    <div className="flex gap-2 w-full">
                        <div className="flex flex-col pl-2 w-full">
                            <div className='flex justify-between gap-2'>
                                <div className="flex gap-2">
                                    <div className={`${item.isWarning ? "" : "hidden"} flex items-center`}>
                                        <InfoOutlined className="bg-[#ffc700] p-[1px] rounded-sm" />
                                    </div>
                                    <img src={soccer_2} alt="" className="lg:w-[18px] lg:h-[18px] xl:w-[18px] xl:h-[18px] xl2:w-full xl2:h-full xl3:w-full xl3:h-full" />
                                    <span className='lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base text-white'>
                                        Soccer
                                    </span>
                                </div>

                                <img src={Trash} alt=""
                                    onClick={() => rStrightBetsSlipByIndex?.(index)}
                                />
                            </div>
                            <div className='flex justify-start gap-2'>
                                <span className='lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base text-white'>{item?.lineBetting}</span>
                            </div>
                            <div className='flex justify-start text-left'>
                                <span className='lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base text-white'>{item.game}</span>
                            </div>

                        </div>
                    </div>

                </div>
                <div className='flex'>

                </div>
            </div>

            <div className='flex justify-between' key={`Parlay-${index}`}>
                <div className='flex justify-between'>
                    {/* <Checkbox
                        checked={item.isCheck}
                        onChange={(e) => handleCheck(!item.isCheck)}
                    ></Checkbox> */}
                    <span className='lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base text-white pl-2'>
                        {`${item.lineBetting.includes("OU") ? "" : item?.teamChoose}`}
                        {/* ${item.lineBetting === "Fulltime HDP" || item.lineBetting === "First Half HDP"
                                ?
                                ``
                                // `${item.scoreChoose.split(" ")[0] ? Number(item.scoreChoose.split(" ")[0]).toFixed(2) : Number(item.scoreChoose.split(" ")[0]).toFixed(2)}`
                                :

                                item.lineBetting === "Fulltime OU" || item.lineBetting === "First Half OU"
                                    ?
                                    `${item.scoreChoose.split(" ")[1].split("-").length > 1 ? Number(item.scoreChoose.split(" ")[1].split("-")[0]).toFixed(2) + "-" + item.scoreChoose.split(" ")[1].split("-")[1] : Number(item.scoreChoose.split(" ")[1]).toFixed(2)}`
                                    :
                                    ``
                            // item.scoreChoose
                            } */}
                    </span>
                    <span className='pl-1 lg:text-[10px] xl:text-xs xl2:text-base xl3:text-base text-white whitespace-nowrap'>
                        {item.lineBetting === "Fulltime HDP" || item.lineBetting === "First Half HDP"
                            ?
                            <>
                                {item.scoreChoose.split(" ").length > 1 ? Number(item.scoreChoose.split(" ")[0]).toFixed(2) + " @ " + Number(item.scoreChoose.split(" ")[1]).toFixed(2) : Number(item.scoreChoose.split(" ")[0]).toFixed(2)}
                            </>
                            :

                            item.lineBetting === "Fulltime OU" || item.lineBetting === "First Half OU"
                                ?
                                `${item.scoreChoose.split(" ")[0].split("-").length > 1 ? Number(item.scoreChoose.split(" ")[0].split("-")[0]).toFixed(2) + "-" + item.scoreChoose.split(" ")[0].split("-")[1] + " @ " + Number(item.scoreChoose.split(" ")[1]).toFixed(2) : Number(item.scoreChoose.split(" ")[0]).toFixed(2)}`
                                :
                                `${item.scoreChoose.split(" ").length > 1 ? item.scoreChoose.split(" ")[0] + " @ " + Number(item.scoreChoose.split(" ")[1]).toFixed(2) : Number(item.scoreChoose)}`
                            // `${item.scoreChoose}`
                        }
                    </span>
                </div>
            </div>


        </div>
    )
}

export default ItemParlaySoccer;