export const API_URL_365 = "https://api.kb365.bdata.cloud";
export const API_URL = "https://api2.kb365.bdata.cloud/";
export const API_URL_SOCCER = "https://api1.kb365.bdata.cloud";

export const ICON_COUNTRY = "https://cms.kb365.bdata.cloud/uploads/iconCountry/";
export const ICON_GAMME = "https://cms.kb365.bdata.cloud/uploads/iconGame/";
export const URL_BANNER = "https://cms.kb365.bdata.cloud/uploads/banner/";

export const ICON_TEAM = "https://media.itsfogo.com/media/upload/prod/participants/";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    process: {
        env: {
            API_URL_365,
            API_URL,
            ICON_COUNTRY,
            ICON_GAMME
        },
    },
};