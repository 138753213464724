/* eslint-disable react/jsx-pascal-case */
import { useEffect, useMemo, useState } from "react";
import { InfoAddStrightSoccerTmp, InfoParlaySoccerTmp } from "../../../../api/betting/bettingApi";
import { formatPrice } from "../../../../until";
import useToast from "../../../../hook/useToast";
import useThrottle from "../../../../hook/useThrottle";
import { Close, warningBetslip } from "../../../../components/imgExport";
import { Drawer, Input } from "antd";
import CaculateComponent_V2 from "./Caculate_V2";
import '../stylesMainNavi.css'
interface TotalStakeProps {
    keyBetTabs: string,
    numberStake: number,

    isWarningBetParlay?: boolean
    textWarningBetParlaySoccer?: string
    showTotalStake: boolean,
    showTotalStake_V2: boolean,
    showTotalBet: boolean,

    totalOddsParlay?: number
    totalTParlay?: number
    totalAmountParlay?: number
    listAddStrightBetsSlip: InfoAddStrightSoccerTmp[]
    listParlayBetsSlip?: InfoParlaySoccerTmp[]

    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    handleChangeInput?: (total: number) => void
    minInputStake?: () => void
    maxInputStake?: () => void
    showHideTotalStake: () => void,
    showHideTotalStake_V2: () => void,
    handleDeleteClick: (index: number) => void
    handleCheckBetStright: () => void
    handleCheckBetParlay?: () => void
    handleCheckQuantityMoneyStraight?: (keyBetTabs: number, total: number) => void
    hiddenBetPlaceSoccer?: boolean,
    minBet?: number,
    maxBet?: number,
}

function isNumber(value?: string | number): boolean {
    return ((value != null) &&
        (value !== '') &&
        !isNaN(Number(value.toString())));
}

const TotalStakeComponentV2 = ({
    keyBetTabs, numberStake, isWarningBetParlay, textWarningBetParlaySoccer,
    showTotalBet, showTotalStake,
    totalOddsParlay, totalTParlay, totalAmountParlay,
    listAddStrightBetsSlip, listParlayBetsSlip, showTotalStake_V2,
    showHideTotalStake_V2, handleClick, showHideTotalStake, handleNumberClick, handleChangeInput, handleDeleteClick, handleCheckBetStright, handleCheckBetParlay, handleCheckQuantityMoneyStraight, minInputStake, maxInputStake,
    hiddenBetPlaceSoccer,
    minBet, maxBet
}: TotalStakeProps) => {

    const pushToast = useToast();


    const [totalBetStright, setTotalBetStright] = useState(0)
    const [totalWinStright, setTotalWinStright] = useState(0)
    const [totalBetParlay, setTotalBetParlay] = useState(0)
    const [totalAmountStright, setTotalAmountStright] = useState(0)
    const [warningParlay, setWarningParlay] = useState<boolean>(false)

    const checkBetParlay = useThrottle(() => {
        if (isWarningBetParlay) {
            pushToast("Parlay cannot be placed as some of your legs cannot be combined", "warn");
        }
        else {
            handleCheckBetParlay?.()
        }
    }, 1000)


    const handleSuccess = () => {
        showHideTotalStake()
        switch (keyBetTabs) {
            case "1":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
            case "2":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountParlay))
                break;
            default:
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
        }

    }
    const handleSuccess_V2 = () => {
        showHideTotalStake_V2()
        switch (keyBetTabs) {
            case "1":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
            case "2":
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, Number(totalAmountParlay))
                break;
            default:
                handleCheckQuantityMoneyStraight?.(+keyBetTabs, +numberStake)
                break;
        }

    }

    const changeInputStake = ((value: any) => {
        handleChangeInput && handleChangeInput(value)
    })

    useMemo(() => {
        const totalAmountTmp = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
        }, 0);
        setTotalAmountStright(totalAmountTmp)
        const totalWin = listAddStrightBetsSlip.reduce((accumulator, currentItem) => {
            var coefficient: any
            if (currentItem.scoreChoose.split(" ").length <= 2) {
                coefficient = isNumber(currentItem.scoreChoose.split(" ")[1]) ? currentItem.scoreChoose.split(" ")[1] : 0 || currentItem.scoreChoose.split(" ")[0]
            } else {
                coefficient = isNumber(currentItem.scoreChoose.split(" ")[currentItem.scoreChoose.split(" ").length - 1]) ? currentItem.scoreChoose.split(" ")[currentItem.scoreChoose.split(" ").length - 1] : 0 || currentItem.scoreChoose.split(" ")[0]
            }
            return currentItem.isCheck ? (accumulator + Number(currentItem.amount * (+coefficient))) : +accumulator;
        }, 0);
        setTotalWinStright(totalWin)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        const filteredArray = listAddStrightBetsSlip.filter((item) => item.isCheck);
        setTotalBetStright(filteredArray.length)
    }, [listAddStrightBetsSlip])

    useMemo(() => {
        // eslint-disable-next-line no-mixed-operators
        const filteredArray = listParlayBetsSlip && listParlayBetsSlip.filter((item) => item.isCheck) || [];
        setTotalBetParlay(filteredArray.length)

    }, [listParlayBetsSlip])

    useEffect(() => {
        setWarningParlay(false)
        if (totalBetParlay >= 3 && keyBetTabs === "2") {
            setTimeout(() => { setWarningParlay(isWarningBetParlay ?? false) }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWarningBetParlay, totalBetParlay])

    return (
        <>
            <div
                className={`border-warning-betslip rounded-[6px] p-1 bg-[#39372E] flex justify-start items-center ${totalBetParlay >= 3 && isWarningBetParlay && warningParlay && keyBetTabs === "2" ? "" : "!hidden"} `}
            // hidden={(!isWarningBetParlay && keyBetTabs === "2") || keyBetTabs === "1" || (totalBetParlay < 2 && keyBetTabs === "2")}
            >
                <img src={warningBetslip} alt="" />
                <span className="pl-[6px] text-left text-[#D4B962] text-[13px] font-[500]">{textWarningBetParlaySoccer ? textWarningBetParlaySoccer : "Parlay cannot be placed as some of your legs cannot be combined"}</span>
            </div>
            <div
                className={`border-warning-betslip rounded-[6px] p-1 text-left bg-[#39372E] flex justify-start items-center ${(totalBetParlay > 2 && keyBetTabs === "2") || keyBetTabs === "1" ? "!hidden" : ""}`}
            >
                <img src={warningBetslip} alt="" />
                <span className="pl-[6px] text-left text-[#D4B962]">3+ selections needed for a Parlay.</span>
            </div>
            <div
                className={`totalStake !bg-[#131620] absolute bottom-0 w-full`}
                hidden={showTotalBet}
            >
                <div className="flex flex-col gap-2">
                    <div className="flex items-start justify-between gap-3">

                        <div className="w-full h-[33px] rounded-xl border border-[#E4E7F1] flex items-center justify-between px-3">
                            {
                                keyBetTabs === '1' && <Input disabled={window.outerWidth >= 1365 ? false : true} bordered={false} placeholder="Stake per bet" type="number" value={numberStake} onChange={(e) => changeInputStake(e.target.value)} className="!text-white placehoder-C1C9E1 input-stake" id="input-stake-mobile" />

                            }
                            {
                                keyBetTabs === '2' && <Input disabled={window.outerWidth >= 1365 ? false : true} bordered={false} placeholder="Stake per bet" type="number" value={totalAmountParlay} onChange={(e) => changeInputStake(e.target.value)} className="!text-white placehoder-C1C9E1 input-stake" id="input-stake-mobile" />
                            }
                            <span className="text-sm text-[#666E97]">$</span>
                        </div>

                    </div>
                    {/* {
                        keyBetTabs === "1" &&
                        <span className='font-bold-GT-America text-[16px] h-[32px] stakeButton text-white' onClick={showHideTotalStake_V2}>
                            {numberStake === 0 ? '' : (<span className="">$</span>)}
                            {numberStake === 0 ? 'Stake per bet' : `${numberStake} `}
                        </span>
                    }
                    {
                        keyBetTabs === "2" &&
                        <span className='font-bold-GT-America text-[16px] h-[32px] stakeButton text-white' onClick={showHideTotalStake_V2}>
                            {totalAmountParlay === 0 ? '' : (<span className="">$</span>)}
                            {totalAmountParlay === 0 ? 'Stake per bet' : `${totalAmountParlay} `}
                        </span>
                    } */}
                    <CaculateComponent_V2
                        showTotalStake={false}
                        showTotalStake_V2={true}
                        numberStake={numberStake}
                        totalAmountParlay={totalAmountParlay ?? undefined}
                        keyBetTabs={keyBetTabs}
                        handleClick={handleClick}
                        handleNumberClick={handleNumberClick}
                        handleDeleteClick={handleDeleteClick}
                        handleSuccess={handleSuccess}
                        showHideTotalStake={showHideTotalStake}
                        maxInputStake={maxInputStake}
                    />
                </div>


                <Drawer
                    placement="bottom"
                    closeIcon={false}
                    className="!bg-[#131620] p-none-drawer xs:!block xl:!hidden"
                    maskClassName='xs:!block xl:!hidden'
                    title={<div className="flex justify-end" >
                        <img src={Close} alt="" onClick={() => showHideTotalStake_V2()} />
                    </div>}
                    open={showTotalStake_V2}
                    onClose={() => showHideTotalStake_V2()}

                >
                    <CaculateComponent_V2
                        showTotalStake={showTotalStake_V2}
                        showTotalStake_V2={showTotalStake_V2}
                        numberStake={numberStake}
                        totalAmountParlay={totalAmountParlay ?? undefined}
                        keyBetTabs={keyBetTabs}
                        handleClick={handleClick}
                        handleNumberClick={handleNumberClick}
                        handleDeleteClick={handleDeleteClick}
                        handleSuccess={handleSuccess_V2}
                        showHideTotalStake={showHideTotalStake_V2}

                    />
                </Drawer>
                <div>
                    {
                        keyBetTabs === "1" &&
                        <>
                            {/* <span
                                className={`flex justify-start pb-2 text-white ${totalBetStright > 1 ? '' : 'hidden'}`}
                            >
                                {totalBetStright} Bets
                            </span> */}
                            <div className="flex flex-col gap-2">
                                <div className="flex justify-between">
                                    <div className='flex  items-center gap-2' onClick={() => minInputStake && minInputStake()}>
                                        <span className="text-[#D4B962] cursor-pointer ">Min</span>
                                        <span className="text-[#D4B962] cursor-pointer">${formatPrice(minBet || 0)}</span>
                                    </div>
                                    <div className='flex items-center gap-2' onClick={() => maxInputStake && maxInputStake()}>
                                        <span className="text-[#D4B962] cursor-pointer text-center ">Max</span>
                                        <span className="text-[#D4B962] cursor-pointer">${formatPrice(maxBet || 0)}</span>
                                    </div>
                                </div>
                                <div className='flex justify-between '>
                                    <span className="text-white text-base">Total stake</span>
                                    <span className='text-white font-bold-GT-America text-base'>${formatPrice(totalAmountStright || 0)}</span>
                                </div>
                                <div className='flex  justify-between '>
                                    <span className="text-white text-base">Total win</span>
                                    <span className='text-[#33E49A] font-bold-GT-America text-base text-right'>{totalWinStright === 0 ? '$0' : `$${formatPrice(Math.round(totalWinStright) || 0)}`}</span>
                                </div>

                            </div>
                            <div className="place-bet-soccer">
                                <button className={`font-bold-GT-America rounded-[2px] uppercase w-full py-[10px] bg-[#D4B962] text-black`} onClick={handleCheckBetStright}>
                                    Place bet
                                </button>
                            </div>
                        </>

                    }
                    {
                        keyBetTabs === "2" &&
                        <>
                            {/* <span
                                className={`flex justify-start pb-2 text-white ${totalBetParlay > 1 ? '' : 'hidden'}`}
                            >
                                {totalBetParlay} Bets
                            </span> */}
                            <div>
                                <div className="flex flex-col gap-2">
                                    <div className="flex justify-between">
                                        <div className='flex  items-center gap-2' onClick={() => minInputStake && minInputStake()}>
                                            <span className="text-[#D4B962] cursor-pointer ">Min</span>
                                            <span className="text-[#D4B962] cursor-pointer">${formatPrice(minBet || 0)}</span>
                                        </div>
                                        <div className='flex items-center gap-2' onClick={() => maxInputStake && maxInputStake()}>
                                            <span className="text-[#D4B962] cursor-pointer text-center ">Max</span>
                                            <span className="text-[#D4B962] cursor-pointer">${formatPrice(maxBet || 0)}</span>
                                        </div>
                                    </div>
                                    <div className='flex  justify-between '>
                                        <span className="text-white text-base">Total odds</span>
                                        <span className='text-white font-bold-GT-America text-base'>{formatPrice((totalOddsParlay || 0).toFixed(2))}</span>
                                    </div>
                                    <div className='flex  justify-between '>
                                        <span className="text-white text-base">Total win</span>
                                        <span className='text-[#33E49A] font-bold-GT-America text-base text-right'>{totalAmountParlay === 0 ? '$0' : `$${formatPrice(Math.round(((totalAmountParlay || 0) + (totalAmountParlay || 0) * ((totalOddsParlay || 1) - 1 || 0) * (totalTParlay || 1))) || 0)}`}</span>

                                    </div>
                                </div>

                                <div
                                >
                                    {!isWarningBetParlay && listParlayBetsSlip && listParlayBetsSlip.length >= 3 && (
                                        // <Button
                                        //     className="!bg-[#D4B962] w-[100%] rounded-md font-bold-GT-America"
                                        //     type="primary"
                                        //     onClick={checkBetParlay}
                                        // >
                                        //     Bet place
                                        // </Button>
                                        <button className={`font-bold-GT-America rounded-[2px] uppercase w-full py-[10px] bg-[#D4B962] text-black`} onClick={checkBetParlay}>
                                            Place bet
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                </div>
                {/* <div className="md:hidden xs:flex">
                    <ToastGlobal />
                </div> */}
            </div >
        </>
    )
}
export default TotalStakeComponentV2